import i18n from "i18nextConf";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { onMobile } from "views/global/App";
import appEn from "../../assets/img/app/appEn.png";
import appFr from "../../assets/img/app/appFr.png";
import MobileAppButtons from "./MobileAppButtons";

function ApplicationMobile() {
	const { t } = useTranslation();

	return (
		<div>
			{!onMobile() ? (
				<div className=" gridColumns mt-6">
					<div className="m-3 paragraph">
						<Container className="px-5">
							<h1 className="text-center ">
								{t("services.mobile")}
							</h1>
							<div className="textKupa text-justify mt-3">
								{t("services.mobile.text")}
							</div>
							<div className="mt-2 d-flex justify-content-center">
								<MobileAppButtons />
							</div>
						</Container>
					</div>
					<div className="d-flex justify-content-center">
						<img
							className="bigImage "
							src={i18n.language === "en" ? appEn : appFr}
							alt="Kupa Station Application Mobile"
						/>
					</div>
				</div>
			) : (
				<div className="mt-7">
					<Container className=" ">
						<div className="px-4">
							<h1 className="text-center">
								{t("services.mobile")}
							</h1>
							<div className="textKupa text-justify mt-2">
								{t("services.mobile.text")}
							</div>
							<div className="mt-4 d-flex justify-content-center">
								<MobileAppButtons />
							</div>
						</div>
					</Container>
					<img
						className="bigImage mt-3 "
						src={i18n.language === "en" ? appEn : appFr}
						alt="Kupa Station Application Mobile"
					/>
				</div>
			)}
		</div>
	);
}

export default ApplicationMobile;
