import i18n from "i18next";
import { useState } from "react";

function LanguageButton({ pastVideo = false, onMobile = false }) {
	const [inEn, setInEn] = useState(i18n.language.includes("en"));

	return (
		<button
			className={!onMobile ? (pastVideo ? "btnPast" : "btn") : "btnPast"}
			onClick={() => {
				i18n.changeLanguage(inEn ? "fr" : "en");
				setInEn(!inEn);
				window.location.reload();
			}}
		>
			{inEn ? "EN" : "FR"}
		</button>
	);
}

export default LanguageButton;
