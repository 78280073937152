import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { onMobile } from "views/global/App";
import "../../assets/css/medias.css";
import thumbnail_en from "../../assets/img/pdf/brochureENG_Thumbnail.jpg";
import thumbnail_fr from "../../assets/img/pdf/brochureFR_Thumbnail.jpg";

function Resource() {
	const { t } = useTranslation();
	const brochures = [
		{
			url: "/pdf/brochure_fr.pdf",
			thumbnail: thumbnail_fr,
			text: t("about.flyer.fr"),
		},
		{
			url: "/pdf/brochure_en.pdf",
			thumbnail: thumbnail_en,
			text: t("about.flyer.en"),
		},
	];

	return (
		<Container className="mt-5 mb-4">
			<div className="text-center">
				<h1>{t("about.ressources")}</h1>
			</div>
			<ul className="p-0" style={{ marginTop: -20 }}>
				<div
					className={`mt-4 ${
						onMobile() ? "medias_mobile" : "about.medias"
					} justify-content-center`}
				>
					{brochures.map((brochure, index) => (
						<li className="element" key={index}>
							<div className="testing d-flex justify-content-center">
								<a
									href={brochure.url}
									target="_blank"
									rel="noreferrer"
								>
									<img
										width={onMobile() ? "200" : "400"}
										height={onMobile() ? "150" : "250"}
										className="imageTest"
										src={brochure.thumbnail}
										alt="kupa station brochures"
									/>

									<div className=" text-center m-0">
										{brochure.text}
									</div>
								</a>
							</div>
						</li>
					))}
				</div>{" "}
			</ul>
		</Container>
	);
}

export default Resource;
