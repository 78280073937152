import "../../assets/css/toggle.css";

function Toggle({ color, onChange, checked }) {
    return (
        <label className="switch">
            <input type="checkbox" onChange={onChange} checked={checked} />
            <span style={{ backgroundColor: checked ? color : null }} className="slider" />
        </label>
    );
}

export default Toggle;