import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { onMobile } from "views/global/App";
import "../../../assets/css/avantage.css";
import Carroussel from "../Carroussel/Carroussel";
import CardAvantages from "./CardAvantages";

function Avantages() {
	const { t } = useTranslation();
	const advantages = [
		{
			title: t("home.benefits.simple"),
			text: t("home.benefits.simple.text"),
		},
		{
			title: t("home.benefits.filtered"),
			text: t("home.benefits.filtered.text"),
		},
		{
			title: t("home.benefits.healthy"),
			text: t("home.benefits.healthy.text"),
		},
		{
			title: t("home.benefits.onedevice"),
			text: t("home.benefits.onedevice.text"),
		},
		{
			title: t("home.benefits.nowaste"),
			text: t("home.benefits.nowaste.text"),
		},
		{ title: t("home.benefits.easy"), text: t("home.benefits.easy.text") },
	];

	const views = [];

	for (let i = 0; i < advantages.length; i += 3) {
		const view = advantages.slice(i, i + 3);
		views.push(view);
	}
	const [page, setPage] = useState(0);
	const [productsPerPage, setproductsPerPage] = useState(2);
	const adjustLayout = () => {
		if (window.innerWidth < 800) {
			setproductsPerPage(1);
		} else {
			setproductsPerPage(2);
		}
	};
	useEffect(() => {
		adjustLayout();

		const handleResize = () => {
			adjustLayout();
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<Container className="mt-2 contenaire">
			<div className="textTitle textKupa my-3 justifyCenter">
				{t("home.intro.flavoroption")}
			</div>

			{!onMobile() ? (
				views.map((view, index) => (
					<div itemId={index + 1} key={index + 1}>
						<div className="d-flex justify-content-center ">
							{view.map((advantage, index) => (
								<div key={index + 1}>
									<CardAvantages
										title={advantage.title}
										text={advantage.text}
									/>
								</div>
							))}
						</div>
					</div>
				))
			) : (
				<Carroussel
					list={advantages.map((advantage, index) => (
						<CardAvantages
							title={advantage.title}
							text={advantage.text}
						/>
					))}
					productsPerPageDefault={productsPerPage}
					page={page}
					setPage={setPage}
				></Carroussel>
			)}
		</Container>
	);
}

export default Avantages;
