import { useTranslation } from "react-i18next";
import AboutInformation from "views/IndexSections/AboutInformation";
import Medias from "views/IndexSections/Medias";
import MetaDecorator from "views/IndexSections/MetoDecorator";
import Resource from "views/IndexSections/Resource";
import van10 from "../../assets/img/van/van10.jpg";
import Footer from "../global/Footer";
// <section className="section section-lg" />

function About() {
	const { t } = useTranslation();

	return (
		<>
			<span id="Services" />
			<MetaDecorator
				title={t("navbar.about")}
				description={"navbar.about"}
				image={van10}
			/>
			<AboutInformation />
			<Medias />
			<Resource />
			<Footer />
		</>
	);
}

export default About;
