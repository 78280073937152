import React, { useState, createContext } from "react";

export const DimensionsContext = createContext({});

export const DimensionsProvider = ({ children }) => {
    const [dimensions, setDimensions] = useState(window.innerWidth);
    const [dimensionsHeight, setDimensionsHeight] = useState(
        window.innerHeight
    );

    return (
        <DimensionsContext.Provider
            value={{
                dimensions,
                setDimensions,
                setDimensionsHeight,
                dimensionsHeight,
            }}
        >
            {children}
        </DimensionsContext.Provider>
    );
};
