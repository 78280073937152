import { stats } from "api/requests.js";
import { useEffect, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import CustomSpinner from "./CustomSpinner";

function Counter() {
	const { t } = useTranslation();

	const [totalOrders, setTotalOrders] = useState(undefined);
	const [fetchError, setFetchError] = useState(false);

	const [status, setStatus] = useState(false);

	useEffect(() => {
		async function fetchData() {
			stats()
				.then((response) => {
					setTotalOrders(response.orderCount);
				})
				.catch((error) => setFetchError(true));
		}
		fetchData();
	}, []);

	function formattedNumber(num) {
		return num.toLocaleString("en-US");
	}

	const onEndStatus = () => setStatus(true);

	return (
		<Container className="mt-2">
			<span id="Counter" />
			{!fetchError &&
				(totalOrders === undefined ? (
					<CustomSpinner size={6} color="white" />
				) : (
					<div className="counter-div">
						<div md="4" className="counter">
							<div className="centerCounter">
								<div className="textCounterBig">
									{t("counters.global")}
								</div>
								<h4 className="  numberCounterBig">
									{status ? (
										formattedNumber(totalOrders)
									) : (
										<CountUp
											className="numberCounterBig"
											delay={0.2}
											end={totalOrders}
											onEnd={onEndStatus}
											duration={1.5}
										/>
									)}
								</h4>
							</div>
						</div>
					</div>
				))}
		</Container>
	);
}

export default Counter;
