import { useTranslation } from "react-i18next";

function PopoverUser() {
	const { t } = useTranslation();

	return (
		<div className="font-weight-bold">
			<div>
				<span>{t("map.yourposition")}</span>
			</div>
		</div>
	);
}

export default PopoverUser;
