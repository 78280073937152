import Icon from "./Icon";

import { faYoutubeSquare } from "@fortawesome/free-brands-svg-icons";

import URLS from "../../../js/Urls";

function YouTubeIcon({ color, size, space, text }) {
	return (
		<Icon
			color={color}
			size={size}
			space={space}
			text={text && "YouTube"}
			url={URLS.YOUTUBE}
			icon={faYoutubeSquare}
		/>
	);
}

export default YouTubeIcon;
