import Icon from "./Icon";

import { faTiktok } from "@fortawesome/free-brands-svg-icons";

import URLS from "../../../js/Urls";

function TokTikIcon({ color, size, space, text }) {
	return (
		<Icon
			color={color}
			size={size}
			space={space}
			text={text && "TikTok"}
			url={URLS.TIKTOK}
			icon={faTiktok}
		/>
	);
}

export default TokTikIcon;
