import { useEffect, useState } from "react";

import {
	faBars,
	faInfoCircle,
	faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import "../../../assets/css/nav.css";
import logo from "../../../assets/img/brand/logo-blue.png";
import logoWhite from "../../../assets/img/brand/logo-white.png";
import { onMobile } from "../App";
import FacebookIcon from "../media/FacebookIcon";
import InstagramIcon from "../media/InstagramIcon";
import LinkedinIcon from "../media/LinkedinIcon";
import TokTikIcon from "../media/TokTikIcon";
import YouTubeIcon from "../media/YouTubeIcon";
import LanguageButton from "./LanguageButton";
import PortalButton from "./PortalButton";

function Nav() {
	const [menuOpen, setMenuOpen] = useState(false);
	const { t } = useTranslation();
	const loc = useLocation().pathname;
	const [pastVideo, setPastVideo] = useState(false);

	const menuItems = onMobile()
		? [
				{
					name: "navbar.home",
					icon: faScrewdriverWrench,
					link: "/",
					onClick: () => window.scrollTo(0, 0),
				},
				{
					name: "navbar.services",
					icon: faScrewdriverWrench,
					link: "/services",
					onClick: () => window.scrollTo(0, 0),
				},
				{
					name: "navbar.about",
					icon: faInfoCircle,
					link: "/about",
					onClick: () => window.scrollTo(0, 0),
				},
		  ]
		: [
				{
					name: "navbar.services",
					icon: faScrewdriverWrench,
					link: "/services",
					onClick: () => window.scrollTo(0, 0),
				},
				{
					name: "navbar.about",
					icon: faInfoCircle,
					link: "/about",
					onClick: () => window.scrollTo(0, 0),
				},
		  ];
	const listenScrollEvent = () => {
		window.scrollY > 200 ? setPastVideo(true) : setPastVideo(false);
	};
	useEffect(() => {
		const isHomePage = loc === "/";
		if (isHomePage) {
			window.addEventListener("scroll", listenScrollEvent);
			listenScrollEvent();
		} else {
			setPastVideo(true);
		}
		return () => {
			window.removeEventListener("scroll", listenScrollEvent);
		};
	}, [loc]);
	return (
		<div className="fixed">
			{!onMobile() ? (
				<ul
					className={!pastVideo ? "navTrans topnav" : "nav topnav"}
					style={{
						boxShadow: !pastVideo
							? undefined
							: "rgba(0, 0, 0, 0.25) 0px 10px 25px -12px",
						//borderBottom: "2px solid rgba(0, 0, 0, 0.1)",
					}}
				>
					<li>
						<Link to="/">
							<img
								style={{
									width: 100,
									marginRight: 50,
								}}
								src={!pastVideo ? logoWhite : logo}
								alt="Kupa Station Logo"
							/>
						</Link>
					</li>

					{menuItems.map((el, index) => (
						<li key={index}>
							<Link to={el.link}>
								<button
									className={` mr-4 custom_button ${
										!pastVideo ? "text-white" : undefined
									} ${
										loc === el.link
											? "choosed_elem"
											: "border-0"
									}`}
									style={{
										background: "transparent",

										fontSize: 15,
									}}
									onClick={el.onClick}
								>
									{t(el.name)}
								</button>
							</Link>
						</li>
					))}
					<li>
						<button
							className={`mr-4 custom_button ${
								!pastVideo ? "text-white" : undefined
							}`}
							style={{
								background: "transparent",
								fontSize: 15,
							}}
							onClick={() =>
								window.scrollTo(0, document.body.scrollHeight)
							}
						>
							{t("navbar.contact")}
						</button>{" "}
					</li>
					<li className="right">
						<PortalButton marginRight={15} pastVideo={pastVideo} />
						<LanguageButton pastVideo={pastVideo} />
						<div className="links" style={{ marginLeft: 15 }}>
							<FacebookIcon
								color={
									!pastVideo
										? "var(--kupawhite)"
										: "var(--kupablack)"
								}
							/>
							<InstagramIcon
								color={
									!pastVideo
										? "var(--kupawhite)"
										: "var(--kupablack)"
								}
							/>
							<TokTikIcon
								color={
									!pastVideo
										? "var(--kupawhite)"
										: "var(--kupablack)"
								}
							/>
							<YouTubeIcon
								color={
									!pastVideo
										? "var(--kupawhite)"
										: "var(--kupablack)"
								}
							/>
							<LinkedinIcon
								color={
									!pastVideo
										? "var(--kupawhite)"
										: "var(--kupablack)"
								}
							/>
						</div>
					</li>
				</ul>
			) : (
				<>
					<ul
						className={
							!pastVideo && !menuOpen
								? "navTrans topnav"
								: "nav topnav"
						}
						style={{
							boxShadow:
								!pastVideo && !menuOpen
									? undefined
									: "rgba(0, 0, 0, 0.25) 0px 10px 25px -12px",
							//borderBottom: "2px solid rgba(0, 0, 0, 0.1)",
						}}
					>
						<li>
							<Link to="/">
								<img
									style={{ width: 100, marginRight: 50 }}
									src={
										!pastVideo && !menuOpen
											? logoWhite
											: logo
									}
									alt="Kupa Station Logo"
								/>
							</Link>
						</li>
						<li className="right">
							<div
								className=""
								style={{
									color:
										!pastVideo && !menuOpen
											? "var(--kupawhite)"
											: "var(--kupablack)",
									fontSize: 30,
								}}
								onClick={() => setMenuOpen((prev) => !prev)}
							>
								<FontAwesomeIcon icon={faBars} />
							</div>
						</li>
					</ul>
					{menuOpen && (
						<div
							className=" mobile_nav"
							style={{
								borderBottom: "2px solid rgba(0, 0, 0, 0.1)",
								boxShadow: "20px",
								padding: "20px 20px 0 0px",
							}}
						>
							<ul className=" ham">
								{menuItems.map((el, index) => (
									<li key={index}>
										<Link to={el.link}>
											<button
												className={`  custom_button  ${
													loc === el.link
														? "choosed_elem"
														: "border-0"
												}`}
												style={{
													background: "transparent",
													color: "#000",
													fontSize: 15,
													padding: 0,
												}}
												onClick={() => {
													el.onClick();
													setMenuOpen(false);
												}}
											>
												{t(el.name)}
											</button>
										</Link>
									</li>
								))}
								<li>
									<button
										className={` custom_button`}
										style={{
											background: "transparent",
											color: "#000",
											fontSize: 15,
											padding: 0,
										}}
										onClick={() => {
											window.scrollTo(
												0,
												document.body.scrollHeight
											);
											setMenuOpen(false);
										}}
									>
										{t("navbar.contact")}
									</button>{" "}
								</li>
								<li className="">
									<PortalButton />
								</li>
							</ul>
							<div
								className=" text-right "
								style={{
									marginTop: "50px",
									//padding: "20px 0px 0 0px",
								}}
							>
								<li>
									<LanguageButton onMobile={true} />
								</li>
								<span
									className="links_mobile"
									style={{
										display: "flex",
										//justifyContent: "right",
									}}
								>
									<FacebookIcon
										color="var(--kupablack)"
										size={20}
									/>
									<InstagramIcon
										color="var(--kupablack)"
										size={20}
									/>
									<TokTikIcon
										color="var(--kupablack)"
										size={18}
									/>
									<YouTubeIcon
										color="var(--kupablack)"
										size={20}
									/>
									<LinkedinIcon
										color="var(--kupablack)"
										size={20}
									/>
								</span>
							</div>
						</div>
					)}
				</>
			)}
		</div>
	);
}
/* */
export default Nav;
