import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "assets/scss/argon-design-system-react.scss?v1.1.0";
import "./assets/css/global.css";
import "./i18nextConf";
import { DimensionsProvider } from "context/DimensionsContext";
import App from "views/global/App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <DimensionsProvider>
            <App/>
        </DimensionsProvider>
    </BrowserRouter>
);
