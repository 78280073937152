import Icon from "./Icon";

import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

import URLS from "../../../js/Urls";

function LinkedinIcon({ color, size, space, text }) {
    return (
        <Icon
            color={color}
            size={size}
            space={space}
            text={text && "Linkedin"}
            url={URLS.LINKEDIN}
            icon={faLinkedin}
        />
    );
}

export default LinkedinIcon;