import { useTranslation } from "react-i18next";
import { onMobile } from "../App";

function PortalButton({ marginRight = 0, pastVideo = false }) {
	const { t } = useTranslation();

	return (
		<button
			className={`  custom_button border-0 `}
			style={{
				background: "transparent",
				fontSize: 15,
				marginRight: marginRight,
				textAlign: onMobile() && "left",
				padding: 0,
				//padding: 8,
				//boxShadow: "rgba(0, 0, 0, 0.25) 0px 5px 10px 0px",
			}}
		>
			<a
				target="_blank"
				href={`https://client.kupastation.com`}
				style={{
					color: "var(--kupablack)",
				}}
				rel="noreferrer"
				className={
					!onMobile()
						? !pastVideo
							? "text-white"
							: undefined
						: undefined
				}
			>
				{t("navbar.managementtool")}
			</a>
		</button>
	);
}

export default PortalButton;
