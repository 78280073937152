import Icon from "./Icon";

import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';

import URLS from "../../../js/Urls";

function FacebookIcon({ color, size, space, text }) {
    return (
        <Icon
            color={color}
            size={size}
            space={space}
            text={text && "Facebook"}
            url={URLS.FACEBOOK}
            icon={faFacebookSquare}
        />
    );
}

export default FacebookIcon;