
// https://youtu.be/zHZRFwWQt2w
// https://youtu.be/uLvhAJfx3T0

export default function Background({ image, color, backgroundX, backgroundY, colorOpacity, padding, className, style, children }) {
    const background = {
        background: `${color} url(${image})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: `${backgroundX ? backgroundX : "0"}% ${backgroundY ? backgroundY : "0"}%`,

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        position: "relative",
        padding: padding,
    };

    const colorOverlay = {
        width: "100%",
        height: "100%",
        background: color,
        opacity: colorOpacity,
        position: "absolute",
    };

    return (
        <div style={{ ...background, ...style }} className={className}>
            <div style={{ zIndex: 1 }}>{children}</div>
            <div style={colorOverlay} />
        </div>
    );
}