import i18n from "i18next";
import "../../assets/css/mobileAppButtons.css";
import appstroreEng from "../../assets/img/app/appstoreen.svg";
import appStroreFr from "../../assets/img/app/appstorefr.svg";
import googleEn from "../../assets/img/app/googleplayen.png";
import googleFr from "../../assets/img/app/googleplayfr.png";
import Urls from "../../js/Urls";

function MobileAppButtons() {
	const OpenGooglePlayApp = () => {
		window.location.href = Urls.GOOGLE_PLAY;
	};
	const OpenAppStoreApp = () => {
		window.location.href = Urls.APPLE_STORE;
	};
	return i18n.language === "en" ? (
		<div className="mt-4 ">
			<img
				onClick={() => OpenGooglePlayApp()}
				alt="Get it on Google Play"
				src={googleEn}
				className="googleEn cursor-pointer"
				style={{ cursor: "pointer" }}
			/>

			<img
				onClick={() => OpenAppStoreApp()}
				src={appstroreEng}
				alt="Download on the App Store"
				style={{ cursor: "pointer" }}
				className="appleEn"
			/>
		</div>
	) : (
		<div className="mt-4 ">
			<img
				onClick={() => OpenGooglePlayApp()}
				alt="Get it on Google Play"
				src={googleFr}
				style={{ cursor: "pointer" }}
				className="googleFr"
			/>

			<img
				onClick={() => OpenAppStoreApp()}
				src={appStroreFr}
				alt="Download on the App Store"
				style={{ cursor: "pointer" }}
				className="appleFr"
			/>
		</div>
	);
}

export default MobileAppButtons;
