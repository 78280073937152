import i18n from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { onMobile } from "views/global/App";
import "../../../assets/css/machine.css";
import machineEn from "../../../assets/img/machine/machineEn.png";
import machineFr from "../../../assets/img/machine/machineFr.png";
import ocean from "../../../assets/img/ocean.png";
import Background from "../Background";

function Machine() {
	const { t } = useTranslation();
	const [alturaTela, setAlturaTela] = useState(
		onMobile() ? "auto" : window.innerHeight - 50
	);
	const english = i18n.language.includes("en");
	useEffect(() => {
		const handleResize = () => {
			setAlturaTela(onMobile() ? "auto" : window.innerHeight - 50);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	return (
		<div>
			<div
				className="mt-2"
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<p
					style={{ padding: 10 }}
					className="textKupa textTitle justifyCenter"
				>
					{t("home.intro")}
				</p>

				<img
					className="machine"
					src={english ? machineEn : machineFr}
					alt="Kupa Station Fontaine d'eau"
					style={{ height: alturaTela }}
				/>
			</div>
			<Background
				image={ocean}
				color="var(--kupablue)"
				colorOpacity={0.8}
				padding={onMobile() ? 40 : 55}
				style={{ marginTop: -150, zIndex: -1 }}
				backgroundX={50}
			></Background>
		</div>
	);
}

export default Machine;
