import { Card, CardBody } from "reactstrap";
import { onMobile } from "views/global/App";

function CardAvantages({ title, text }) {
	return (
		<Card className="zoom shadow-on-zoom Card m-1">
			<CardBody
				style={{
					height: onMobile() ? "250px" : "250px",
					width: onMobile() ? "300px" : "300px",
				}}
			>
				<h4
					style={{ color: "var(--kupablue)" }}
					className="text-center mb-3"
				>
					{title}
				</h4>
				<div className="textKupa text-justify">{text}</div>
			</CardBody>
		</Card>
	);
}

export default CardAvantages;
