import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Icon({ color, size, space, url, icon, text }) {
	return (
		<a
			style={{
				color: color,

				marginRight: space,
			}}
			href={url}
			target="_blank"
			rel="noreferrer"
		>
			<FontAwesomeIcon
				icon={icon}
				style={{ marginRight: text && 7, height: size, width: "auto" }}
			/>
			{text}
		</a>
	);
}

export default Icon;
