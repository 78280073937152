import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSwipe } from "js/useSwipe";

const Carroussel = ({ list = [], productsPerPage = 1, page, setPage }) => {
	const totalPages = Math.ceil(list.length / productsPerPage);
	const startIndex = page * productsPerPage;
	const endIndex = startIndex + productsPerPage;
	const visibleAdvantages = list.slice(startIndex, endIndex);
	const turnLeft = () => {
		if (page + 1 === totalPages) {
			setPage(0);
			return;
		}
		setPage((prev) => prev + 1);
	};
	const turnRight = () => {
		if (page === 0) {
			setPage(totalPages - 1);
			return;
		}
		setPage((prev) => prev - 1);
	};
	const swipeHandlers = useSwipe({
		onSwipedLeft: () => {
			turnLeft();
		},
		onSwipedRight: () => {
			turnRight();
		},
	});
	return (
		<>
			<div
				className="products "
				onTouchStart={swipeHandlers.onTouchStart}
				onTouchMove={swipeHandlers.onTouchMove}
				onTouchEnd={swipeHandlers.onTouchEnd}
			>
				<div className="left_arrow" onClick={turnRight}>
					<FontAwesomeIcon icon={faAngleLeft} size="2xl" />
				</div>
				<div className="right_arrow" onClick={turnLeft}>
					<FontAwesomeIcon icon={faAngleRight} size="2xl" />
				</div>
				{visibleAdvantages.map((advantage, index) => advantage)}
			</div>
			<div className="products_types mt-3">
				<div className="products_pages">
					{Array.from({ length: totalPages }, (_, index) => (
						<div className="rounded-point-div">
							<div
								key={index}
								onClick={() => setPage(index)}
								className={`rounded-point ${
									page === index
										? "rounded-point-selected"
										: undefined
								}`}
							></div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default Carroussel;
