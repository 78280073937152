import Urls from "js/Urls";
import { Trans, useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import "../../assets/css/certifications.css";
import EcoResponsable from "../../assets/img/certifications/EcoResponsable.png";
import FabriqueQuebec from "../../assets/img/certifications/FariqueQuebecRGB_BLACK_WHITE.png";
import { onMobile } from "../global/App.js";

function Certifications() {
	const { t } = useTranslation();
	const certifications = [
		{
			image: FabriqueQuebec,
			descripstion: (
				<>
					<div className="certification-title-1">
						<p className="text-nowrap">
							{t("home.certifications.miq")}
						</p>
					</div>

					<Trans i18nKey="home.certifications.miq.text"></Trans>

					<a
						target="_blank"
						rel="noreferrer"
						href={Urls.FABRIQUE_QUEBEC}
						style={{
							wordWrap: "break-word",
							overflowWrap: "break-word",
						}}
					>
						{Urls.FABRIQUE_QUEBEC}
					</a>
				</>
			),
		},
		{
			image: EcoResponsable,
			descripstion: (
				<>
					<div className="certification-title-1">
						<p>{t("home.certifications.eco")}</p>
					</div>
					<Trans i18nKey="home.certifications.eco.text"></Trans>

					<a
						target="_blank"
						rel="noreferrer"
						href={Urls.PAGES_VERTES}
						style={{
							wordWrap: "break-word",
							overflowWrap: "break-word",
						}}
					>
						{Urls.PAGES_VERTES}
					</a>
				</>
			),
		},
	];

	return (
		<>
			<div className="mb-5">
				<Container fluid>
					<div className="justify-content-center">
						<div className="text-center" lg="12">
							<h1>{t("home.certifications")}</h1>
						</div>
					</div>
					<div
						className={
							onMobile()
								? "grid-certifications-mobile"
								: "grid-certifications"
						}
					>
						{certifications.map((certification, index) => (
							<div
								key={index}
								className={
									onMobile()
										? "certification-card-mobile"
										: "certification-card"
								}
							>
								<img
									className="certification-img"
									src={certification.image}
									alt={`Kupa Station ${t("home.partners")}`}
								/>
								<div className="textKupa certification-text">
									{certification.descripstion}
								</div>
							</div>
						))}
					</div>
				</Container>
			</div>
		</>
	);
}

export default Certifications;
