import { useContext, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import About from "views/routes/About";
import { DimensionsContext } from "../../context/DimensionsContext";
import Messenger from "../IndexSections/Messenger.js";
import Home from "../routes/Home.js";
import Services from "../routes/Services.js";
import Nav from "./navbar/Nav.js";
import DownloadApp from "views/routes/DownloadApp";

export function onMobile() {
	return window.innerWidth < 950;
}

function App() {
	const { setDimensions, setDimensionsHeight } =
		useContext(DimensionsContext);
	useEffect(() => {
		const handleResize = function handleResize() {
			setDimensions(window.innerWidth);
			setDimensionsHeight(window.innerHeight);
		};

		window.addEventListener("resize", handleResize);
		return (_) => {
			window.removeEventListener("resize", handleResize);
		};
	});

	return (
		<div className="grid-cols-2 grid">
			<div className="">
				<Nav />
			</div>
			<div className="">
				<Switch>
					<Route
						path="/"
						exact
						render={(props) => <Home {...props} />}
					/>

					<Route
						path="/services"
						exact
						render={(props) => <Services {...props} />}
					/>
					<Route
						path="/about"
						exact
						render={(props) => <About {...props} />}
					/>
					<Route
						path="/download-app"
						exact
						render={(props) => <DownloadApp {...props} />}
					/>

					<Redirect to="/" />
				</Switch>
			</div>

			<Messenger />
		</div>
	);
}

export default App;
